<template>
    <div class="redirect-plt">
        <spinner v-if="!error" size="21px" title="loading..." />

        <div v-else>Link is incorrect</div>
    </div>
</template>

<script>
import axios from 'axios';

import Spinner from '~/components/Spinner';

import { adjustColor } from '~/utils/color.js';

export default {
    layout: 'static',

    components: { Spinner},

    data() {
        return {
            organizations: [],
            allSites: [],
            error: false,
        }
    },

    computed: {
        siteId() {
            return this.$route.query.site_id;
        },

        uuid() {
            return this.$route.query.uuid;
        },

        user() {
            return this.$store.getters["auth/user"];
        },

        siteIds() {
            const ids = this.user.sitesFilter
                .filter(item => { return item.id !== +this.siteId })
                .map(item => { return item.id });

            ids.unshift(this.siteId);

            return ids;
        }
    },
    
    methods: {
        async fetchOrganizations() {
            const { data } = await axios.get(this.$apiUrl.organizations.base + '?pageSize=1000&include=sites')
            this.organizations = data.data
        },

        async fetchSites() {
            const { data: { data } } = await axios.get(this.$apiUrl.sites.base + '?pageSize=1000');
            this.allSites = data;
        },

        setBranding(id) {
            let organization = null;

            this.organizations.forEach(item => {
                item.sites.forEach(site => {
                    if (site.id === id) {
                        organization = item;
                    }
                })
            })

            if (!organization) {
                return;
            }

            this.$cookies.set('brand_color', organization.brand_color);
            this.$cookies.set('logo_url', organization.logo_url);

            document.documentElement.style.setProperty('--brand-color', organization.brand_color);
            document.documentElement.style.setProperty('--brand-color-secondary', adjustColor(organization.brand_color));
            document.documentElement.style.setProperty('--logo', organization.logo_url);
        },

        async fetchSaveSites() {
            await axios.post('/api/users/sites-filter', { site_ids: this.siteIds });
        },

        async redirectToPLT() {
            const site = this.allSites
                .find(item => +item.id === +this.siteId);


            if (!site) {
                this.error = true;

                return;
            }

            await this.fetchSaveSites();

            await this.$store.dispatch('auth/fetchUser');

            await localStorage.setItem('role', 'client');

            await this.setBranding(site.id);

            await this.$store.commit("sidebar/ADMIN_MENU", 'client');
            await this.$store.commit("sidebar/LAST_CLIENT_PATH", this.$route.name);
            await this.$store.commit('sidebar/RELOAD');

            this.$router.push({path: `/transport/price-leadtime?uuid=${this.$route.query.uuid}`});
        }
    },

    async mounted() {
        if (!this.uuid) {
            this.error = true;

            return;
        }
        await Promise.all([
            this.fetchOrganizations(),
            this.fetchSites()
        ]);

        await this.redirectToPLT();
    }
}
</script>

<style lang="scss">
.redirect-plt {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}
    
</style>